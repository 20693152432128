<template>
  <Header
    ref="headerRef"
    :title="t('menus.Reports')"
    type="SIGNIFICANT_HOLDINGS"
    :show-regenerate-button="false"
    :updated-on="updatedTime ? t('common.updatedOn') + utils.dateFormatting(updatedTime) + ' UTC' : ''"
  />

  <ReportsMenus ref="ReportsMenusRef" type="SIGNIFICANT_HOLDINGS" :title="t('project.nav.SignificantHoldings')" />

  <GuideStep v-if="reportStore.entityDetail?.guideStep < 3" type="SIGNIFICANT_HOLDINGS" />

  <div v-else v-loading.fullscreen.lock="loading">
    <ProcessTable ref="processTableRef" type="holdings" group-name="currency" :height="tableHeight" />
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import ProcessTable from '../components/ProcessTable.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const loading = ref(false)
const processTableRef = ref()
const headerRef = ref()
const ReportsMenusRef = ref()
const reportStore = useReportStore()
const { entityDetail } = storeToRefs(reportStore)
const tableConfigStore = useReportsTableConfigStore()

const updatedTime = computed(() => {
  return entityDetail.value.updatedOn
})

const tableHeight = computed(() => {
  return ReportsMenusRef.value?.isShowRegeneratedInfo ? `calc(100vh - 259px)` : `calc(100vh - 233px)`
})

const titleBottom = computed(() => {
  return ReportsMenusRef.value?.isShowRegeneratedInfo ? `0px` : `16px`
})

watch(
  route,
  async (newValue) => {
    loading.value = true
    if (newValue.name === 'reports-significant-holdings') {
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('holdings')
    }
    setTimeout(() => {
      loading.value = false
    }, 300)
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
:deep(.elv-report-title) {
  margin-bottom: v-bind('titleBottom');
}

:deep(.elv-financial-process-table) {
  padding-top: 0px;
}
</style>
